// constants
import { LayoutActionTypes } from "./constants";

import config from "../../config";
import { Response } from "../../types/response";
import { Driver } from "../../types/driver";
import { Accesory, accesorySchema } from "../../types/accesory";
import { Trip } from "../../types/trip";
import { Gender } from "../../types/gender";

const baseURL = config.API_URL;

export interface LayoutActionType<TPayload> {
  type:
    | LayoutActionTypes.CHANGE_LAYOUT_MODE
    | LayoutActionTypes.CHANGE_TWOCOLUMN_THEME
    | LayoutActionTypes.CHANGE_LAYOUT
    | LayoutActionTypes.CHANGE_LAYOUT_COLOR
    | LayoutActionTypes.CHANGE_LAYOUT_WIDTH
    | LayoutActionTypes.CHANGE_MENU_POSITIONS
    | LayoutActionTypes.CHANGE_SIDEBAR_THEME
    | LayoutActionTypes.CHANGE_SIDEBAR_TYPE
    | LayoutActionTypes.TOGGLE_SIDEBAR_USER_INFO
    | LayoutActionTypes.CHANGE_TOPBAR_THEME
    | LayoutActionTypes.TOGGLE_TWO_TONE_ICONS
    | LayoutActionTypes.SHOW_RIGHT_SIDEBAR
    | LayoutActionTypes.HIDE_RIGHT_SIDEBAR;
  payload?: TPayload;
}

export const changeLayoutModes = (mode: string): LayoutActionType<string> => ({
  type: LayoutActionTypes.CHANGE_LAYOUT_MODE,
  payload: mode,
});

export const changeTwoColumnThemes = (
  mode: string,
): LayoutActionType<string> => {
  return {
    type: LayoutActionTypes.CHANGE_TWOCOLUMN_THEME,
    payload: mode,
  };
};

export const changeLayout = (layout: string): LayoutActionType<string> => ({
  type: LayoutActionTypes.CHANGE_LAYOUT,
  payload: layout,
});

export const changeLayoutColor = (color: string): LayoutActionType<string> => ({
  type: LayoutActionTypes.CHANGE_LAYOUT_COLOR,
  payload: color,
});

export const changeLayoutWidth = (width: string): LayoutActionType<string> => ({
  type: LayoutActionTypes.CHANGE_LAYOUT_WIDTH,
  payload: width,
});

export const changeMenuPositions = (
  position: string,
): LayoutActionType<string> => ({
  type: LayoutActionTypes.CHANGE_MENU_POSITIONS,
  payload: position,
});

export const changeSidebarTheme = (
  sidebarTheme: string,
): LayoutActionType<string> => ({
  type: LayoutActionTypes.CHANGE_SIDEBAR_THEME,
  payload: sidebarTheme,
});

export const changeSidebarType = (
  sidebarType: string,
): LayoutActionType<string> => {
  return {
    type: LayoutActionTypes.CHANGE_SIDEBAR_TYPE,
    payload: sidebarType,
  };
};

export const toggleSidebarUserInfo = (
  showSidebarUserInfo: boolean,
): LayoutActionType<boolean> => ({
  type: LayoutActionTypes.TOGGLE_SIDEBAR_USER_INFO,
  payload: showSidebarUserInfo,
});

export const changeTopbarTheme = (
  topbarTheme: string,
): LayoutActionType<string> => ({
  type: LayoutActionTypes.CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
});

export const toggleTwoToneIcons = (
  showTwoToneIcons: boolean,
): LayoutActionType<boolean> => ({
  type: LayoutActionTypes.TOGGLE_TWO_TONE_ICONS,
  payload: showTwoToneIcons,
});

export const showRightSidebar = (): LayoutActionType<null> => ({
  type: LayoutActionTypes.SHOW_RIGHT_SIDEBAR,
});

export const hideRightSidebar = (): LayoutActionType<null> => ({
  type: LayoutActionTypes.HIDE_RIGHT_SIDEBAR,
});

const getAuthToken = () =>
  JSON.parse(sessionStorage.getItem("ubold_user") ?? "{}").token;

const checkLoginStatus = () => {
  return Boolean(getAuthToken());
};

export const get_all_countrycode_list = async () => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "get_all_countrycode_list", {
      method: "GET",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const get_all_country_list = async () => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "get_all_country_list", {
      method: "GET",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const get_all_state_list = async () => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "get_all_state_list", {
      method: "GET",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const get_all_gender_list = async () => {
  const authToken = getAuthToken();
  if (!authToken) {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    } as const;
  }

  var res = await fetch(baseURL + "get_all_gender_list", {
    method: "GET",
    headers: {
      accept: "application.json",
      "Content-Type": "application/json",
      "x-access-token": authToken,
    },
  })
    .then(async (response) => {
      var res = (await response.json()) as Response<{
        genderlist: Gender[];
      }>;

      return res;
    })
    .catch((err) => {
      console.log(err.message);
      return null;
    });

  return res;
};

export const get_all_wallet_list_for_driver = async () => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "get_all_wallet_list_for_driver", {
      method: "GET",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const get_all_wallet_list_for_rider = async () => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "get_all_wallet_list_for_rider", {
      method: "GET",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_add_or_edit_driver = async (
  id: string,
  firstname: string,
  lastname: string,
  email: string,
  password: string,
  country_code: string,
  gender: string,
  mobile: string,
  company: string,
  address_line_1: string,
  address_line_2: string,
  city: string,
  state: string,
  postal_code: number,
  country: string,
  driving_license_no: string,
  driving_license_image: any,
  expire_date: string,
  driving_license_status: string,
  account_holder_name: string,
  account_number: number,
  name_of_bank: string,
  bank_location: string,
  ifsc_code: string,
  usertype: number,
  isactive: number,
) => {
  const authToken = JSON.parse(
    sessionStorage.getItem("ubold_user") ?? "",
  ).token;

  if (authToken) {
    var res = await fetch(baseURL + "admin_add_or_edit_driver", {
      method: "POST",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": authToken,
      },
      body: JSON.stringify({
        id: id,
        firstname: firstname,
        lastname: lastname,
        email: email,
        password: password,
        country_code: country_code,
        gender: gender,
        mobile: mobile,
        company: company,
        address_line_1: address_line_1,
        address_line_2: address_line_2,
        city: city,
        state: state,
        postal_code: postal_code,
        country: country,
        driving_license_no: driving_license_no,
        driving_license_image: driving_license_image,
        expire_date: expire_date,
        driving_license_status: driving_license_status,
        account_holder_name: account_holder_name,
        account_number: account_number,
        name_of_bank: name_of_bank,
        bank_location: bank_location,
        ifsc_code: ifsc_code,
        usertype: usertype,
        isactive: isactive,
      }),
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_get_all_driver_list = async () => {
  const authToken = getAuthToken();
  if (authToken) {
    var res = await fetch(baseURL + "admin_get_all_driver_list", {
      method: "GET",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": authToken,
      },
    })
      .then(async (response) => {
        var res = (await response.json()) as Response<{ driverlist: Driver[] }>;

        return res;
      })
      .catch((err) => {
        console.log(err.message);
        return undefined;
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    } as const;
  }
};

export const admin_delete_driver_by_id = async (id: string) => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_delete_driver_by_id", {
      method: "DELETE",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
      body: JSON.stringify({ id: id }),
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_get_driver_by_id = async (id: string) => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_get_driver_by_id", {
      method: "POST",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
      body: JSON.stringify({ id: id }),
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_add_or_edit_rider = async (
  id: string,
  firstname: string,
  lastname: string,
  email: string,
  password: string,
  country_code: number,
  gender: string,
  mobile: string,
  whatsapp_number: string,
  isactive: number,
  updated_by: number,
) => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_add_or_edit_rider", {
      method: "POST",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
      body: JSON.stringify({
        id: id,
        firstname: firstname,
        lastname: lastname,
        email: email,
        password: password,
        country_code: country_code,
        gender: gender,
        mobile: mobile,
        whatsapp_number: whatsapp_number,
        isactive: isactive,
        updated_by: updated_by,
      }),
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_get_all_rider_list = async () => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_get_all_rider_list", {
      method: "GET",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_delete_rider_by_id = async (id: string) => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_delete_rider_by_id", {
      method: "DELETE",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
      body: JSON.stringify({ id: id }),
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_get_rider_by_id = async (id: string) => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_get_rider_by_id", {
      method: "POST",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
      body: JSON.stringify({ id: id }),
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_add_or_edit_document = async (
  id: string,
  document_name: string,
  uploading_for: number,
  driver: number,
  isactive: number,
  updated_by: number,
) => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_add_or_edit_document", {
      method: "POST",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
      body: JSON.stringify({
        id: id,
        document_name: document_name,
        uploading_for: uploading_for,
        driver: driver,
        isactive: isactive,
        updated_by: updated_by,
      }),
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_get_all_document_list = async () => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_get_all_document_list", {
      method: "GET",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_get_document_by_id = async (id: string) => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_get_document_by_id", {
      method: "POST",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
      body: JSON.stringify({ id: id }),
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_send_email = async (
  id: string,
  email_to: number,
  subject: string,
  message: string,
  email: string,
  user_group: number,
  isactive: number,
  updated_by: number,
) => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_send_email", {
      method: "POST",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
      body: JSON.stringify({
        id: id,
        email_to: email_to,
        subject: subject,
        message: message,
        email: email,
        user_group: user_group,
        isactive: isactive,
        updated_by: updated_by,
      }),
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_send_notification = async (
  id: string,
  user_type: number,
  message: string,
  isactive: number,
  updated_by: number,
) => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_send_notification", {
      method: "POST",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
      body: JSON.stringify({
        id: id,
        user_type: user_type,
        message: message,
        isactive: isactive,
        updated_by: updated_by,
      }),
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_get_all_vehicletype_list = async () => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_get_all_vehicletype_list", {
      method: "GET",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_add_or_edit_vehicle = async (
  id: string,
  vehicle_company_name: string,
  driver_id: number,
  vehicle_model: string,
  vehicle_no: string,
  document_type: any,
  vehicle_owner_type: number,
  vehicle_status: number,
  vehicle_type: any,
  color: string,
  isactive: number,
  updated_by: number,
) => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_add_or_edit_vehicle", {
      method: "POST",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
      body: JSON.stringify({
        id: id,
        vehicle_company_name: vehicle_company_name,
        driver_id: driver_id,
        vehicle_model: vehicle_model,
        vehicle_no: vehicle_no,
        document_type: document_type,
        vehicle_owner_type: vehicle_owner_type,
        vehicle_status: vehicle_status,
        vehicle_type: vehicle_type,
        color: color,
        isactive: isactive,
        updated_by: updated_by,
      }),
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_get_all_vehicle_list = async () => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_get_all_vehicle_list", {
      method: "GET",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_delete_vehicle_by_id = async (id: string) => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_delete_vehicle_by_id", {
      method: "DELETE",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
      body: JSON.stringify({ id: id }),
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_get_vehicle_by_id = async (id: string) => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_get_vehicle_by_id", {
      method: "POST",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
      body: JSON.stringify({ id: id }),
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_get_basefare = async () => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_get_basefare", {
      method: "GET",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_add_or_edit_basefare = async (
  id: string,
  basefare: number,
  cancellation_charge: number,
  distancefare: number,
  distance: number,
  isactive: number,
  updated_by: number,
) => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_add_or_edit_basefare", {
      method: "POST",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
      body: JSON.stringify({
        id: id,
        basefare: basefare,
        cancellation_charge: cancellation_charge,
        distancefare: distancefare,
        distance: distance,
        isactive: isactive,
        updated_by: updated_by,
      }),
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_get_all_peaktimefare = async () => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_get_all_peaktimefare", {
      method: "GET",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_add_or_edit_peaktimefare = async (
  peaktimefarelist: object,
  updated_by: number,
) => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_add_or_edit_peaktimefare", {
      method: "POST",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
      body: JSON.stringify({
        peaktimefarelist: peaktimefarelist,
        updated_by: updated_by,
      }),
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_get_all_highdemandlocationfare = async () => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_get_all_highdemandlocationfare", {
      method: "GET",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_add_or_edit_highdemandlocationfare = async (
  highdemandlocationfarelist: object,
  updated_by: number,
) => {
  if (checkLoginStatus()) {
    var res = await fetch(
      baseURL + "admin_add_or_edit_highdemandlocationfare",
      {
        method: "POST",
        headers: {
          accept: "application.json",
          "Content-Type": "application/json",
          "x-access-token": getAuthToken(),
        },
        body: JSON.stringify({
          highdemandlocationfarelist: highdemandlocationfarelist,
          updated_by: updated_by,
        }),
      },
    )
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_get_all_weatherfare = async () => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_get_all_weatherfare", {
      method: "GET",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_add_or_edit_weatherfare = async (
  weatherfarelist: object,
  updated_by: number,
) => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_add_or_edit_weatherfare", {
      method: "POST",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
      body: JSON.stringify({
        weatherfarelist: weatherfarelist,
        updated_by: updated_by,
      }),
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_get_driver_policy_list = async () => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_get_driver_policy_list", {
      method: "GET",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_add_or_edit_driver_policy = async (
  id: String,
  alw_driver_add_money_wal: Number,
  send_mail: Number,
  min_wallet_bal: Number,
  isset_wal_terms: Number,
  terms_conditions: String,
  comm_type: Number,
  commission_flat: Number,
  commission_percent: Number,
  fare_management_scenario: Number,
  isactive: number,
  updated_by: number,
) => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_add_or_edit_driver_policy", {
      method: "POST",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
      body: JSON.stringify({
        id: id,
        alw_driver_add_money_wal: alw_driver_add_money_wal,
        send_mail: send_mail,
        min_wallet_bal: min_wallet_bal,
        isset_wal_terms: isset_wal_terms,
        terms_conditions: terms_conditions,
        comm_type: comm_type,
        commission_flat: commission_flat,
        commission_percent: commission_percent,
        fare_management_scenario: fare_management_scenario,
        isactive: isactive,
        updated_by: updated_by,
      }),
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_get_rider_policy_list = async () => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_get_rider_policy_list", {
      method: "GET",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_add_or_edit_rider_policy = async (
  id: String,
  alw_cus_add_money_wal: Number,
  alw_promo_refrl_trnsfr_wal: Number,
  set_prtl_pay_wal_percent: Number,
  wal_percent: Number,
  isactive: number,
  updated_by: number,
) => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_add_or_edit_rider_policy", {
      method: "POST",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
      body: JSON.stringify({
        id: id,
        alw_cus_add_money_wal: alw_cus_add_money_wal,
        alw_promo_refrl_trnsfr_wal: alw_promo_refrl_trnsfr_wal,
        set_prtl_pay_wal_percent: set_prtl_pay_wal_percent,
        wal_percent: wal_percent,
        isactive: isactive,
        updated_by: updated_by,
      }),
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_get_all_location_list = async () => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_get_all_location_list", {
      method: "GET",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_get_location_by_id = async (id: string) => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_get_location_by_id", {
      method: "POST",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
      body: JSON.stringify({ id: id }),
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_add_or_edit_location = async (
  id: string,
  address1: string,
  address2: string,
  city: string,
  district: string,
  state: number,
  pincode: number,
  country: number,
  lat: number,
  lng: number,
  place_id: string,
  isactive: number,
  updated_by: number,
) => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_add_or_edit_location", {
      method: "POST",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
      body: JSON.stringify({
        id: id,
        address1: address1,
        address2: address2,
        city: city,
        district: district,
        state: state,
        pincode: pincode,
        country: country,
        lat: lat,
        lng: lng,
        place_id: place_id,
        isactive: isactive,
        updated_by: updated_by,
      }),
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_add_or_edit_accesory = async (data: Accesory) => {
  if (checkLoginStatus()) {
    console.log({ data });
    var res = await fetch(baseURL + "admin_add_or_edit_accessory", {
      method: "POST",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        var res = await response.json();

        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

export const admin_get_all_accessory_list = async () => {
  const authToken = getAuthToken();
  if (authToken) {
    var res = await fetch(baseURL + "admin_get_all_accessory_list", {
      method: "GET",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": authToken,
      },
    })
      .then(async (response) => {
        var res = (await response.json()) as Response<{
          accessorylist: Accesory[];
        }>;

        return res;
      })
      .catch((err) => {
        console.log(err.message);
        return undefined;
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    } as const;
  }
};

export const admin_get_accessory_by_id = async (id: string) => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_get_accessory_by_id", {
      method: "POST",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
      body: JSON.stringify({ id: id }),
    })
      .then(async (response) => {
        var res = (await response.json()) as Response<{
          accessory: Accesory;
        }>;

        return res;
      })
      .catch((err) => {
        console.log(err.message);
        return undefined;
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    } as const;
  }
};

export const admin_delete_accessory_by_id = async (id: string) => {
  if (checkLoginStatus()) {
    var res = await fetch(baseURL + "admin_delete_accessory_by_id", {
      method: "DELETE",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": getAuthToken(),
      },
      body: JSON.stringify({ id: id }),
    })
      .then(async (response) => {
        var res = (await response.json()) as Response;

        return res;
      })
      .catch((err) => {
        console.log(err.message);
        return undefined;
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    };
  }
};

// export const admin_add_or_edit_accesory = async (data: Accesory) => {
//   if (checkLoginStatus()) {
//     console.log({ data });
//     var res = await fetch(baseURL + "admin_add_or_edit_accessory", {
//       method: "POST",
//       headers: {
//         accept: "application.json",
//         "Content-Type": "application/json",
//         "x-access-token": getAuthToken(),
//       },
//       body: JSON.stringify(data),
//     })
//       .then(async (response) => {
//         var res = await response.json();

//         return res;
//       })
//       .catch((err) => {
//         console.log(err.message);
//       });

//     return res;
//   } else {
//     return {
//       success: false,
//       errorMessage: "Login again, session has expired!",
//     };
//   }
// };

export const admin_get_all_trip_list = async () => {
  const authToken = getAuthToken();
  if (authToken) {
    var res = await fetch(baseURL + "admin_get_all_trip_list", {
      method: "GET",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        "x-access-token": authToken,
      },
    })
      .then(async (response) => {
        var res = (await response.json()) as Response<{
          triplist: Trip[];
        }>;

        return res;
      })
      .catch((err) => {
        console.log(err.message);
        return undefined;
      });

    return res;
  } else {
    return {
      success: false,
      errorMessage: "Login again, session has expired!",
    } as const;
  }
};

// export const admin_get_accessory_by_id = async (id: string) => {
//   if (checkLoginStatus()) {
//     var res = await fetch(baseURL + "admin_get_accessory_by_id", {
//       method: "POST",
//       headers: {
//         accept: "application.json",
//         "Content-Type": "application/json",
//         "x-access-token": getAuthToken(),
//       },
//       body: JSON.stringify({ id: id }),
//     })
//       .then(async (response) => {
//         var res = (await response.json()) as Response<{
//           accessory: Accesory;
//         }>;

//         return res;
//       })
//       .catch((err) => {
//         console.log(err.message);
//         return undefined;
//       });

//     return res;
//   } else {
//     return {
//       success: false,
//       errorMessage: "Login again, session has expired!",
//     } as const;
//   }
// };

// export const admin_delete_accessory_by_id = async (id: string) => {
//   if (checkLoginStatus()) {
//     var res = await fetch(baseURL + "admin_delete_accessory_by_id", {
//       method: "DELETE",
//       headers: {
//         accept: "application.json",
//         "Content-Type": "application/json",
//         "x-access-token": getAuthToken(),
//       },
//       body: JSON.stringify({ id: id }),
//     })
//       .then(async (response) => {
//         var res = (await response.json()) as Response;

//         return res;
//       })
//       .catch((err) => {
//         console.log(err.message);
//         return undefined;
//       });

//     return res;
//   } else {
//     return {
//       success: false,
//       errorMessage: "Login again, session has expired!",
//     };
//   }
// };
